import request from '@/utils/request';
import download from '@/utils/download';

/**
 * 班组成员列表
 */
export const memberList = data => {
  return request({
    url: '/empTeam/member/list',
    method: 'post',
    data
  });
};

/**
 * 导出班组成员列表
 */
export const downloadMember = params => {
  return download({
    url: '/empTeam/member/download',
    data: params
  });
};

/**
 * 撤销签约
 */
export const doRevoke = params => {
  return request({
    url: '/empTeam/member/doRevoke',
    method: 'post',
    data: params
  });
};

/**
 * 立即签约
 */
export const doSign = params => {
  return request({
    url: '/empTeam/member/doSign',
    method: 'post',
    data: params
  });
};

/**
 * 根据班组编号和劳务公司编号获取签约项目列表
 */
export const teamProjectSelectList = params => {
  return request({
    url: '/empTeam/signed/teamProjectSelectList',
    method: 'post',
    data: params
  });
};

/**
 * 获取已签约班组列表
 */
export const teamSelectList = params => {
  return request({
    url: '/empTeam/signed/teamSelectList',
    method: 'post',
    data: params
  });
};

/**
 * 根据班组编号获取签约劳务公司列表
 */
export const teamTaxsSelectList = params => {
  return request({
    url: '/empTeam/signed/teamTaxsSelectList',
    method: 'post',
    data: params
  });
};

/**
 * 班组成员批量签约保存
 */
export const memberSave = params => {
  return request({
    url: '/empTeam/member/save',
    method: 'post',
    data: params
  });
};