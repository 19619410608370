var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.title,
      "before-close": _vm.handleClose,
      visible: _vm.dialogFormVisible,
      "close-on-click-modal": false
    },
    on: {
      opened: _vm.opened,
      "update:visible": function ($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "选择班组:",
      "label-width": _vm.formLabelWidth,
      prop: "taxNo"
    }
  }, [_c("el-select", {
    staticClass: "w100",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "请选择班组",
      size: "mini"
    },
    on: {
      change: _vm.teamTaxsSelectListAPI
    },
    model: {
      value: _vm.form.teamNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "teamNo", $$v);
      },
      expression: "form.teamNo"
    }
  }, _vm._l(_vm.teamList, function (item) {
    return _c("el-option", {
      key: item.teamNo,
      attrs: {
        label: item.teamName,
        value: item.teamNo
      }
    });
  }), 1)], 1), _vm._v(" "), _vm.form.teamName ? _c("el-form-item", {
    attrs: {
      label: "班组长姓名:",
      "label-width": _vm.formLabelWidth
    }
  }, [_vm._v("\n      " + _vm._s(_vm.form.monitorName) + "\n    ")]) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属劳务公司:",
      "label-width": _vm.formLabelWidth,
      prop: "taxNo"
    }
  }, [_c("el-select", {
    staticClass: "w100",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "请选择签约劳务公司",
      size: "mini"
    },
    on: {
      change: _vm.teamProjectSelectListAPI
    },
    model: {
      value: _vm.form.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "taxNo", $$v);
      },
      expression: "form.taxNo"
    }
  }, _vm._l(_vm.teamTaxsList, function (item) {
    return _c("el-option", {
      key: item.taxNo,
      attrs: {
        label: item.taxName,
        value: item.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属项目:",
      "label-width": _vm.formLabelWidth,
      prop: "projectNo"
    }
  }, [_c("el-select", {
    staticClass: "w100",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "请选择签约项目",
      size: "mini"
    },
    on: {
      change: _vm.teamProjectChange
    },
    model: {
      value: _vm.form.projectNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "projectNo", $$v);
      },
      expression: "form.projectNo"
    }
  }, _vm._l(_vm.projectList, function (item) {
    return _c("el-option", {
      key: item.projectNo,
      attrs: {
        label: item.projectName,
        value: item.projectNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "上传班组签约成员：",
      prop: "checkFile"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      action: _vm.memberUploadURL,
      data: _vm.exData,
      "before-upload": _vm.beforeUpload,
      "on-remove": _vm.handleRemove,
      "file-list": _vm.form.fileList,
      "on-exceed": _vm.handleExceed,
      "on-change": _vm.changeFiles,
      "auto-upload": false,
      "on-success": _vm.onSuccess,
      "on-error": _vm.onError,
      limit: 1
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "mini",
      type: "success"
    },
    slot: "trigger"
  }, [_vm._v("选取文件")]), _vm._v(" "), _c("el-link", {
    staticClass: "download_label",
    attrs: {
      type: "primary",
      href: _vm.templateUrl,
      target: "_blank",
      download: "模版"
    }
  }, [_vm._v("下载模板")]), _vm._v(" "), _c("div", {
    staticClass: "title-span"
  }, [_vm._v("只能上传excel文件，文件数据不超过300条，且大小不超过2M。")])], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v("取 消")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onSure
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };