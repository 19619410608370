import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import DialogMemberSignApply from "../components/dialog/dialogMemberSignApply";
import { mapGetters } from 'vuex';
import { memberList, downloadMember, doRevoke, doSign } from '@/api/members';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { downloadExcel, separateDate } from '@/utils/util';
import { downloadAllFile } from '@/utils/util';
import { downloadFile } from '@/api/upload';
export default {
  name: 'MembersList',
  components: {
    ItemTitle,
    Pagination,
    DialogMemberSignApply,
    DbDatePicker
  },
  data() {
    return {
      loading: false,
      download: '',
      downloadAgreementBtn: '',
      doSignBtn: '',
      signApplyBtn: '',
      revokeBtn: '',
      searchForm: {
        "keyWords": "",
        "teamName": "",
        "teamNo": "",
        "monitorName": "",
        "monitorMobile": "",
        "signTimeBegin": "",
        "signTimeEnd": "",
        "status": "",
        "importBatchNo": "",
        "monitorIdcard": ""
      },
      datePicker: [],
      list: [],
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      dialogMember: false
    };
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    console.log(map);
    if (map) {
      this.download = map.get('download');
      this.downloadAgreementBtn = map.get('downloadAgreement');
      this.doSignBtn = map.get('doSign');
      this.signApplyBtn = map.get('signApply');
      this.revokeBtn = map.get('revoke');
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.queryForm();
    });
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    memberSignStatus() {
      return this.$formatTypeArray('memberSignStatus');
    }
  },
  methods: {
    queryForm() {
      this.currentSearch = {
        pageNo: 1,
        pageSize: 10
      };
      this.getList();
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.searchForm = this.$options.data().searchForm;
      this.datePicker = [];
      this.getList();
    },
    async getList() {
      this.loading = true;
      const {
        keyWords,
        teamName,
        teamNo,
        monitorName,
        monitorMobile,
        monitorIdcard
      } = this.searchForm;
      const {
        pageNo,
        pageSize
      } = this.currentSearch;
      const {
        beginTime,
        endTime
      } = separateDate(this.datePicker);
      const params = {
        keyWords,
        teamName,
        teamNo,
        monitorName,
        monitorMobile,
        monitorIdcard,
        signTimeBegin: beginTime,
        signTimeEnd: endTime,
        pageNo,
        pageSize
      };
      const res = await memberList(params);
      this.list = res.data.list;
      this.total = res.data.totalCount;
      this.loading = false;
    },
    onDoSignConfirm(index, row) {
      this.$confirm('请确认班组成员已通过其他方式完成签约后进行本操作', '温馨提示', {
        type: 'warning',
        confirmButtonText: '确认已签约',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        this.onDoSign(row);
      }).catch(() => {});
    },
    onDoSign(row) {
      const params = {
        id: row.id
      };
      doSign(params).then(response => {
        this.$message({
          type: 'success',
          message: '操作成功'
        });
        this.getList();
      });
    },
    doRevokeConfirm(index, row) {
      this.$confirm('是否确定撤销该签约申请？', '温馨提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        this.doRevoke(row);
      }).catch(() => {});
    },
    doRevoke(row) {
      const params = {
        id: row.id
      };
      doRevoke(params).then(response => {
        this.$message({
          type: 'success',
          message: '操作成功'
        });
        this.getList();
      });
    },
    downloadAgreement(index, row) {
      // todo:下载协议 contractUrl
      downloadFile(row.contractUrl).then(response => {
        downloadAllFile(response.data, row.contractName);
      });
    },
    downloadForm() {
      // todo：调用导出接口
      downloadMember(this.searchForm).then(response => {
        downloadExcel(response.data, '班组成员列表.xls');
      });
    },
    onSuccess() {
      this.getList();
    },
    gotoSign() {
      // todo：签约弹窗
      this.dialogMember = true;
    }
  }
};