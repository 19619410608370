// 枚举汇总定义
export const dic = {
  // 通用状态
  statusType: [{
    label: 0,
    name: '待审核'
  }, {
    label: 1,
    name: '正常'
  }, {
    label: 2,
    name: '冻结'
  }, {
    label: 9,
    name: '审核失败'
  }],
  // 园区状态
  taxStatusType: [{
    label: 0,
    name: '未启用'
  }, {
    label: 1,
    name: '正常'
  }, {
    label: 2,
    name: '冻结'
  }, {
    label: 9,
    name: '作废'
  }],
  // 园区企业签约状态
  taxMerchantSignStatus: [{
    label: 0,
    name: '待审核'
  }, {
    label: 1,
    name: '正常'
  }, {
    label: 2,
    name: '冻结'
  }],
  // 园区个人月度限额额度
  taxQuotaType: [{
    label: 0,
    name: '月度限额'
  }, {
    label: 1,
    name: '季度限额'
  }],
  scopeType: [{
    label: 0,
    name: '不弹框'
  }, {
    label: 1,
    name: '弹框'
  }],
  // 审核状态
  appStatusType: [{
    label: 0,
    name: '审核失败'
  }, {
    label: 1,
    name: '审核中'
  }, {
    label: 2,
    name: '审核通过'
  }],
  // 角色类型
  roleType: [{
    label: 1,
    name: '管理员'
  }, {
    label: 2,
    name: '普通用户'
  }],
  // 客户类型
  partyType: [{
    label: 0,
    name: '平台'
  }, {
    label: 1,
    name: '劳务公司'
  }, {
    label: 2,
    name: '渠道'
  }, {
    label: 3,
    name: '企业'
  }, {
    label: 4,
    name: '项目'
  }],
  // API权限类型
  apiType: [{
    label: 1,
    name: '内部接口'
  }, {
    label: 2,
    name: '外部接口'
  }],
  // API权限状态
  apiStatus: [{
    label: 1,
    name: '有效'
  }, {
    label: 2,
    name: '无效'
  }],
  // 菜单类型
  menuType: [{
    label: 0,
    name: '目录'
  }, {
    label: 1,
    name: '菜单'
  }, {
    label: 2,
    name: '按钮'
  }],
  // 是否
  isHidden: [{
    label: false,
    name: '是'
  }, {
    label: true,
    name: '否'
  }],
  // 用户状态
  userStatus: [{
    label: 0,
    name: '无效'
  }, {
    label: 1,
    name: '锁定'
  }, {
    label: 2,
    name: '正常'
  }],
  // 用户类型
  userType: [{
    label: 0,
    name: '超级管理员'
  }, {
    label: 1,
    name: '系统管理员'
  }, {
    label: 9,
    name: '普通用户'
  }],
  // 黑白名单对象类型
  dimsTypes: [{
    label: 0,
    name: '商户编号'
  }, {
    label: 1,
    name: '银行卡号'
  }, {
    label: 2,
    name: '手机号码'
  }, {
    label: 3,
    name: 'IP地址'
  }],
  // 订单来源(0-WEB，1-API)
  orderSource: [{
    label: 0,
    name: 'WEB'
  }, {
    label: 1,
    name: 'API'
  }, {
    label: 2,
    name: 'API2'
  }],
  // 银行账号类型(0-对公,1-对私)，交易账户类型
  bankType: [{
    label: 0,
    name: '对公'
  }, {
    label: 1,
    name: '对私'
  }],
  // 付款账号类型(0-对公银行卡,1-对私银行卡, 2-支付宝)，交易账户类型
  accountType: [{
    label: 0,
    name: '单位银行卡'
  }, {
    label: 1,
    name: '个人银行卡'
  }, {
    label: 2,
    name: '支付宝账户'
  }, {
    label: 3,
    name: '微信账户'
  }],
  // 付款类型：0-直接发放，1-班组代发
  repayType: [{
    label: 0,
    name: '直接发放'
  }, {
    label: 1,
    name: '班组代发'
  }],
  // 通知状态 0-待通知,1-已通知,2-无需通知
  notifyStatus: [{
    label: 0,
    name: '待通知'
  }, {
    label: 1,
    name: '已通知'
  }, {
    label: 2,
    name: '无需通知'
  }],
  // 资金账户类型
  bankCardType: [{
    label: 0,
    name: '充值账号'
  }, {
    label: 1,
    name: '回款账号'
  }],
  // 记账状态
  accountStatus: [{
    label: 0,
    name: '待记账'
  }, {
    label: 1,
    name: '已记账'
  }, {
    label: 2,
    name: '无需记账'
  }],
  // 对账文件生成状态
  reconFileStatus: [{
    label: 0,
    name: '生成中'
  }, {
    label: 1,
    name: '生成成功'
  }, {
    label: 2,
    name: '生成失败'
  }],
  // 调账类型
  adjustType: [{
    label: 0,
    name: '充值账户转授信账户'
  }, {
    label: 1,
    name: '差错账调整'
  }],
  // 调账状态
  adjustStatus: [{
    label: 0,
    name: '待处理'
  }, {
    label: 1,
    name: '审核成功'
  }, {
    label: 2,
    name: '审核失败'
  }, {
    label: 3,
    name: '调账成功'
  }, {
    label: 4,
    name: '调账失败'
  }],
  // 收益类型
  profitType: [{
    label: 0,
    name: '代付收益'
  }, {
    label: 1,
    name: '奖励收益'
  }],
  // repayment
  repayment: [{
    label: 1,
    name: '代付'
  }, {
    label: 2,
    name: '提现'
  }],
  reconStatus: [{
    label: 0,
    name: '待处理'
  }, {
    label: 1,
    name: '对账成功'
  }, {
    label: 2,
    name: '对账异常'
  }],
  bankStatusArray: [{
    label: 1,
    name: '代付处理中'
  }, {
    label: 2,
    name: '代付成功'
  }, {
    label: 3,
    name: '代付失败'
  }],
  withdrawAuditStatuts: [{
    label: 0,
    name: '无需审核'
  }, {
    label: 1,
    name: '需审核'
  }],
  // new added by zhaowei
  /*
  批次状态
    0-待复核，
    1-已复核待处理，
    2-已撤销
    3-付款处理成功，
    7-付款处理失败，
    9-复核拒绝
  */
  batchStatus: [{
    label: 0,
    name: '待复核'
  }, {
    label: 1,
    name: '已复核待处理'
  }, {
    label: 2,
    name: '已撤销'
  }, {
    label: 3,
    name: '付款处理成功'
  }, {
    label: 7,
    name: '付款处理失败'
  }, {
    label: 9,
    name: '复核拒绝'
  }],
  // 客户经理状态
  managerStatus: [{
    label: 0,
    name: '离职'
  }, {
    label: 1,
    name: '正常'
  }, {
    label: 2,
    name: '注销'
  }],
  // 交易业务状态
  transactionBusinessStatus: [{
    label: -2,
    name: '待处理'
  }, {
    label: 8,
    name: '待计费'
  }, {
    label: -1,
    name: '待调账'
  }, {
    label: 0,
    name: '待付款'
  }, {
    label: 1,
    name: '处理中'
  }, {
    label: 2,
    name: '成功'
  }, {
    label: 3,
    name: '失败'
  }, {
    label: 9,
    name: '待签约'
  }],
  // 交易对账状态
  transactionReconStatus: [{
    label: 0,
    name: '待对账'
  }, {
    label: 1,
    name: '对账完成'
  }, {
    label: 2,
    name: '对账异常'
  }],
  // 交易记账状态
  transactionAccountStatus: [{
    label: 0,
    name: '待记账'
  }, {
    label: 1,
    name: '已记账'
  }, {
    label: 2,
    name: '无需记账'
  }],
  agentTypeStatutes: [{
    label: 0,
    name: '个人服务商'
  }, {
    label: 1,
    name: '企业服务商'
  }],
  // 业务类型
  businessType: [{
    label: 0,
    name: '充值'
  }, {
    label: 1,
    name: '调账'
  }, {
    label: 2,
    name: '代付'
  }, {
    label: 3,
    name: '提现'
  }, {
    label: 4,
    name: '分润'
  }, {
    label: 5,
    name: '返税'
  }, {
    label: 6,
    name: '退款'
  }],
  // 资金方向
  fundDirection: [{
    label: 1,
    name: '可用金额加'
  }, {
    label: 2,
    name: '冻结金额加'
  }, {
    label: 3,
    name: '资金内部冻结'
  }, {
    label: 4,
    name: '资金内部解冻'
  }, {
    label: 5,
    name: '可用金额减'
  }, {
    label: 6,
    name: '冻结金额减'
  }],
  // 所属机构类别
  belongType: [{
    label: 0,
    name: '平台'
  }, {
    label: 1,
    name: '劳务公司'
  }, {
    label: 2,
    name: '服务商'
  }, {
    label: 3,
    name: '甲方单位'
  }, {
    label: 4,
    name: '项目'
  }],
  // 提现状态
  withdrawStatus: [{
    label: 0,
    name: '已申请'
  }, {
    label: 1,
    name: '审核通过'
  }, {
    label: 2,
    name: '已出款'
  }, {
    label: 3,
    name: '审核失败'
  }],
  // 提现状态
  rechargeType: [{
    label: 0,
    name: '系统自动'
  }, {
    label: 1,
    name: '人工录入'
  }],
  // 充值状态 (0-待确认,1-待支付,2-支付中,3-待记账,4-已记账,5-充值失败,9-无效充值)
  rechargeStatus: [{
    label: 0,
    name: '待确认'
  }, {
    label: 1,
    name: '待支付'
  }, {
    label: 2,
    name: '支付中'
  }, {
    label: 3,
    name: '待记账'
  }, {
    label: 4,
    name: '已记账'
  }, {
    label: 5,
    name: '充值失败'
  }, {
    label: 9,
    name: '无效'
  }],
  feeStatutes: [{
    label: 0,
    name: '不返还'
  }, {
    label: 1,
    name: '按月返还'
  }, {
    label: 2,
    name: '按年返还'
  }],
  // 发票状态 6-待审核,7-审核通过待开票, 1-已开票待邮寄, 2-已邮寄, 8-审核拒绝, 9-已取消
  invoiceStatus: [
  // { label: 0, name: '待上传履约函' },
  {
    label: 1,
    name: '已开票待邮寄'
  }, {
    label: 2,
    name: '已邮寄'
  }, {
    label: 6,
    name: '待审核'
  }, {
    label: 7,
    name: '审核通过待开票'
  }, {
    label: 8,
    name: '审核拒绝'
  }, {
    label: 9,
    name: '已取消'
  }],
  // (0-待审核,1-待出票, 2-已完成, 8-审核拒绝, 9-已取消)
  teamInvoiceStatus: [{
    label: 0,
    name: '待审核'
  }, {
    label: 1,
    name: '待出票'
  }, {
    label: 2,
    name: '已完成'
  }, {
    label: 8,
    name: '审核拒绝'
  }, {
    label: 9,
    name: '已取消'
  }],
  // 发票类型
  invoiceType: [{
    label: 0,
    name: '增值税普通发票'
  }, {
    label: 1,
    name: '增值税专用发票'
  }, {
    label: 2,
    name: '差额发票'
  }],
  // 发票开具类型
  invoiceOpenType: [{
    label: 0,
    name: '个人'
  }, {
    label: 1,
    name: '企业'
  }],
  sexType: [{
    label: 0,
    name: '女'
  }, {
    label: 1,
    name: '男'
  }],
  empStatus: [{
    label: 0,
    name: '未认证'
  }, {
    label: 1,
    name: '已认证'
  }, {
    label: 9,
    name: '认证失败'
  }],
  empSignStatus: [{
    label: 0,
    name: '未签约'
  }, {
    label: 1,
    name: '已签约'
  }, {
    label: 2,
    name: '已解约'
  }],
  empSignType: [{
    label: 0,
    name: '企业录入'
  }, {
    label: 1,
    name: '自助注册'
  }],
  rateTypeStatus: [{
    label: 1,
    name: '按月累计'
  }, {
    label: 3,
    name: '按季累计'
  }, {
    label: 2,
    name: '按年累计'
  }],
  agentRateTypeStatus: [{
    label: 0,
    name: '不累计'
  }, {
    label: 1,
    name: '按月累计'
  }, {
    label: 3,
    name: '按季累计'
  }, {
    label: 2,
    name: '按年累计'
  }],
  merchantInvoiceType: [{
    label: 0,
    name: '差额发票'
  }, {
    label: 1,
    name: '全额发票'
  }],
  consultStatus: [{
    label: 0,
    name: '待处理'
  }, {
    label: 1,
    name: '已处理'
  }, {
    label: 9,
    name: '已关闭'
  }],
  // 充值入账-入账类型
  recordedType: [{
    label: 0,
    name: '商户充值'
  }, {
    label: 1,
    name: '代理分润'
  }, {
    label: 2,
    name: '商户税费'
  }, {
    label: 3,
    name: '技术服务费'
  }],
  taxpayerTypeStatus: [{
    label: 0,
    name: '一般纳税人'
  }, {
    label: 1,
    name: '小规模纳税人'
  }, {
    label: 2,
    name: '其他(差额发票)'
  }],
  authType: [{
    label: 0,
    name: '三要素验证'
  }, {
    label: 1,
    name: '四要素验证'
  }, {
    label: 2,
    name: '二要素验证'
  }],
  channelType: [{
    label: 'TAX_OFFLINE',
    name: '劳务公司付款'
  }, {
    label: 'PAY_SPECIAL',
    name: '农民工专户'
  }, {
    label: 'PAY_SELF',
    name: '经营者自付'
  }, {
    label: 'CMBC',
    name: '民生银行'
  }
  // { label: 'BJB', name: '北京银行' },
  // { label: 'CCB', name: '建设银行(网金)' },
  // { label: 'CCBZX', name: '建设银行(专线)' },
  // { label: 'MYBANK', name: '网商银行' },
  // { label: 'ZFB', name: '企业支付宝' },
  // { label: 'WEIXIN', name: '微信支付' }
  ],
  isSupport: [{
    label: 0,
    name: '不支持'
  }, {
    label: 1,
    name: '支持'
  }],
  taxStatus: [{
    label: 0,
    name: '待处理'
  }, {
    label: 1,
    name: '转账中'
  }, {
    label: 2,
    name: '成功'
  }, {
    label: 3,
    name: '失败'
  }],
  taxRouterStatus: [{
    label: 1,
    name: '正常'
  }, {
    label: 0,
    name: '未启用'
  }, {
    label: 2,
    name: '停用'
  }],
  routerAccountStatus: [{
    label: 1,
    name: '已调账'
  }, {
    label: 0,
    name: '待调账'
  }, {
    label: 2,
    name: '无需调账'
  }],
  appInfoStatus: [{
    label: 2,
    name: '正常'
  }, {
    label: 1,
    name: '冻结'
  }],
  needIdcardPicStatus: [{
    label: 1,
    name: '需要'
  }, {
    label: 0,
    name: '不需要'
  }],
  taxChannelBaseType: [{
    label: 0,
    name: '基准'
  }, {
    label: 1,
    name: '专用'
  }],
  rechargeAutoAudit: [{
    label: 0,
    name: '否'
  }, {
    label: 1,
    name: '是'
  }],
  isTrueFalse: [{
    label: 0,
    name: '否'
  }, {
    label: 1,
    name: '是'
  }],
  isHasNo: [{
    label: 0,
    name: '无'
  }, {
    label: 1,
    name: '有'
  }],
  yearLargeSwitch: [{
    label: 0,
    name: '关闭'
  }, {
    label: 1,
    name: '打开'
  }],
  // 退款状态
  refundStatus: [{
    label: 0,
    name: '待确认'
  },
  // { label: 1, name: '待记账' },
  {
    label: 2,
    name: '已记账'
  }, {
    label: 9,
    name: '无效'
  }],
  algorithmType: [{
    label: 'SM2'
  }, {
    label: 'RSA2'
  }],
  chargeType: [{
    label: 0,
    name: '企业充值扣费'
  }
  // { label: 1, name: '个人出款扣费' },
  // { label: 2, name: '企业出款扣费' }
  ],
  // 发票关联状态
  invoiceAssociateStatus: [{
    label: 0,
    name: '未关联'
  }, {
    label: 1,
    name: '已关联'
  }],
  valuationType: [{
    label: 0,
    name: '按工时'
  }, {
    label: 1,
    name: '按次'
  }, {
    label: 2,
    name: '按月'
  }, {
    label: 3,
    name: '按成果'
  }],
  projectStatus: [{
    label: -1,
    name: '未生效'
  }, {
    label: 0,
    name: '待进行'
  }, {
    label: 1,
    name: '进行中'
  }, {
    label: 2,
    name: '已完成'
  }, {
    label: 9,
    name: '已取消'
  }],
  taskStatus: [{
    label: 0,
    name: '审核中'
  }, {
    label: 1,
    name: '进行中'
  }, {
    label: 2,
    name: '已暂停'
  }, {
    label: 3,
    name: '已取消'
  }, {
    label: 4,
    name: '已结束'
  }, {
    label: 5,
    name: '复核中'
  }, {
    label: 9,
    name: '已驳回'
  }],
  taskRecordStatus: [{
    label: 1,
    name: '未审核'
  }, {
    label: 2,
    name: '审核通过'
  }, {
    label: 3,
    name: '审核未通过'
  }],
  parkStatType: [{
    label: 1,
    name: '按商户归属园区'
  }, {
    label: 2,
    name: '按入账园区'
  }],
  cumulativeType: [{
    label: 1,
    name: '全额'
  }, {
    label: 0,
    name: '差额'
  }],
  agentLevelStatutes: [{
    label: 1,
    name: '合伙人'
  }, {
    label: 2,
    name: '城市服务商'
  }, {
    label: 3,
    name: '税务顾问'
  }, {
    label: 4,
    name: '城市推荐人'
  }],
  rateSetUp: [{
    label: 0,
    name: '未设置'
  }, {
    label: 1,
    name: '已设置'
  }],
  agentProfitType: [{
    label: 0,
    name: '企业'
  }, {
    label: 1,
    name: '个人'
  }],
  agentProfitDetailType: [{
    label: 0,
    name: '直客'
  }, {
    label: 1,
    name: '渠道'
  }],
  agentMerchantStatus: [{
    label: 0,
    name: '无效'
  }, {
    label: 1,
    name: '正常'
  }, {
    label: 2,
    name: '冻结'
  }],
  agentProfitRecordStatus: [{
    label: -1,
    name: '确认失败'
  }, {
    label: 0,
    name: '未确认'
  }, {
    label: 1,
    name: '已确认'
  }, {
    label: 2,
    name: '已申请提现'
  }],
  accepterType: [{
    label: 0,
    name: '所有商户'
  }, {
    label: 1,
    name: '所有服务商'
  }, {
    label: 2,
    name: '指定账号'
  }],
  sysMessageStatus: [{
    label: 0,
    name: '待发布'
  }, {
    label: 1,
    name: '已发布'
  }, {
    label: 2,
    name: '已撤回'
  }, {
    label: 3,
    name: '已取消'
  }, {
    label: 4,
    name: '已下线'
  }],
  noticeType: [{
    label: 0,
    name: '悬浮公告'
  }, {
    label: 1,
    name: '站内通知'
  }, {
    label: 2,
    name: '短信通知'
  }],
  openType: [{
    label: 0,
    name: '通知详情'
  }, {
    label: 1,
    name: 'H5链接'
  }],
  popupFlag: [{
    label: 0,
    name: '消息中心'
  }, {
    label: 1,
    name: '首页弹窗'
  }],
  publishType: [{
    label: 0,
    name: '立即发布'
  }, {
    label: 1,
    name: '定时发布'
  }],
  halfBodyStatus: [{
    label: 0,
    name: '待提交'
  }, {
    label: 1,
    name: '已通过'
  }, {
    label: 2,
    name: '已失败'
  }],
  switchStatus: [{
    label: 0,
    name: '关闭'
  }, {
    label: 1,
    name: '开启'
  }],
  taskReportStatus: [{
    label: 0,
    name: '待确定'
  }, {
    label: 1,
    name: '已确认'
  }],
  extraType: [{
    label: 1,
    name: '文本'
  }, {
    label: 2,
    name: '下拉选择'
  }],
  annexStatus: [{
    label: 0,
    name: '解析中'
  }, {
    label: 1,
    name: '解析成功'
  }, {
    label: 2,
    name: '部分成功'
  }, {
    label: 3,
    name: '解析失败'
  }],
  appCode: [{
    label: 1,
    name: '小程序'
  }
  // { label: 2, name: '后台' },
  // { label: 3, name: 'h5' },
  // { label: 9, name: '其他' }
  ],
  // API权限状态
  bannerStatus: [{
    label: 1,
    name: '有效'
  }, {
    label: 2,
    name: '无效'
  }],
  gjSyncStatus: [{
    label: 0,
    name: '待同步'
  }, {
    label: 1,
    name: '已同步'
  }, {
    label: 2,
    name: '同步失败'
  }, {
    label: 9,
    name: '无需同步'
  }],
  gjUserRole: [{
    label: 1,
    name: '服务商'
  }, {
    label: 2,
    name: '员工'
  }],
  taxDjStatus: [{
    label: 0,
    name: '未处理'
  }, {
    label: 1,
    name: '正常'
  }, {
    label: 2,
    name: '异常'
  }],
  taxLsswdjStatus: [{
    label: 0,
    name: '已注销'
  }, {
    label: 1,
    name: '已登记'
  }],
  queryDimension: [{
    label: 1,
    name: '按园区'
  }, {
    label: 2,
    name: '按全国'
  }],
  lsswdjStatus: [{
    label: 9,
    name: '待登记'
  }, {
    label: 1,
    name: '已登记'
  }, {
    label: 0,
    name: '已注销'
  }],
  gthdjStatus: [{
    label: 9,
    name: '待注册'
  }, {
    label: 1,
    name: '已注册'
  }, {
    label: 0,
    name: '已注销'
  }],
  taxGthdStatus: [{
    label: 0,
    name: '已注销'
  }, {
    label: 1,
    name: '已注册'
  }],
  blackType: [{
    label: 1,
    name: '个人身份证'
  }, {
    label: 2,
    name: '个人姓名'
  }, {
    label: 3,
    name: '企业董监高'
  }, {
    label: 4,
    name: '企业编号'
  }],
  taxWszmtatus: [{
    label: 0,
    name: '待推送'
  }, {
    label: 1,
    name: '已推送'
  }],
  reconStatus2: [{
    label: 1,
    name: '无差错'
  }, {
    label: 2,
    name: '平台长款'
  }, {
    label: 3,
    name: '通道长款'
  }, {
    label: 4,
    name: '其他差错'
  }, {
    label: 5,
    name: '差错已处理'
  }],
  voucherType: [{
    label: 1,
    name: '纸质发票'
  }, {
    label: 2,
    name: '自制凭证'
  }],
  taskReportSwitch: [{
    label: 0,
    name: '关闭'
  }, {
    label: 1,
    name: '开启'
  }],
  pushStatus: [{
    label: 0,
    name: '推送中'
  }, {
    label: 1,
    name: '推送成功'
  }, {
    label: 2,
    name: '推送失败'
  }],
  // 项目状态：1-进行中，9-已冻结
  projectStatusType: [{
    label: 1,
    name: '进行中'
  }, {
    label: 9,
    name: '已冻结'
  }],
  // 申请状态(0-待审核，1-审核通过，2-审核拒绝，3-取消申请)
  leaderApplyStatus: [{
    label: 0,
    name: '待审核'
  }, {
    label: 1,
    name: '审核通过'
  }, {
    label: 2,
    name: '审核拒绝'
  }, {
    label: 3,
    name: '已取消'
  }],
  // 申请状态(1-未补充，4-已补充)
  leaderStatus: [{
    label: 1,
    name: '未补充'
  }, {
    label: 4,
    name: '已补充'
  }],
  // 付款类型：0-自动付款，1-人工付款
  payStatus: [{
    label: 0,
    name: '自动付款'
  }, {
    label: 1,
    name: '人工付款'
  }],
  // 班组签约类型：
  groupSignStatus: [{
    label: 0,
    name: '待签约'
  }, {
    label: 1,
    name: '已签约'
  }, {
    label: 9,
    name: '已撤销'
  }],
  // 班组成员签约状态： 0-发起失败，1-待签约，2-已签约，9-已撤销
  memberSignStatus: [{
    label: 0,
    name: '发起失败'
  }, {
    label: 1,
    name: '待签约'
  }, {
    label: 2,
    name: '已签约'
  }, {
    label: 9,
    name: '已撤销'
  }]
};
export const formatTypeArray = type => {
  return dic[type];
};
export function formatType(type, value) {
  const obj = dic[type];
  let name = '-';
  obj.forEach(item => {
    if (item.label === value) {
      name = item.name;
    }
  });
  return name;
}
import Fecha from 'fecha';
/**
 * 日期格式化，返回 yyyy-MM-dd
 */
export function formatDate(value) {
  return value ? Fecha.format(new Date(value), 'YYYY-MM-DD') : '';
}
export function formatDateInTable(row, column, value) {
  let date = value;
  if (column == null && value == null) {
    date = row;
  }
  if (date == null || date === '') {
    return '';
  }
  return formatDate(date);
}
/**
 * 时间格式化，返回 yyyy-MM-dd HH:mm:ss
 */
export function formatDateTime(value) {
  return value ? Fecha.format(new Date(value), 'YYYY-MM-DD HH:mm:ss') : '';
}

// 用于table格式化枚举值
export function formatTypeInTable(type) {
  return function (row, column, value) {
    return formatType(type, value);
  };
}

// 分转元
export function formatAmount2yuan(number) {
  return isNaN(number) ? 0.00 : parseFloat((number / 100).toFixed(2));
}

// 分转元
export function amount2yuan(number) {
  return isNaN(number) ? 0.00 : parseFloat((number / 100).toFixed(2));
}

// 千位逗号分隔
export function fmoney(s, n) {
  if (s == null || s === '') {
    return '';
  }
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
  const l = s.split('.')[0].split('').reverse();
  const r = s.split('.')[1];
  let t = '';
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '');
  }
  return t.split('').reverse().join('') + '.' + r;
}

// 用于table格式化金额，如果用于别的地方可以只传一个金额
export function fmoneyWithSymbol(row, column, value) {
  let amount = value;
  if (column == null && value == null) {
    amount = row;
  }
  if (amount == null || amount === '') {
    return '';
  }
  return '￥' + fmoney(amount);
}

// 金额 + '元'
export function fmoneyWithYuan(value) {
  if (value == null || value === '') {
    return '';
  }
  return fmoney(value) + ' 元';
}