import { isExcelFile } from '@/utils/util';
import { teamProjectSelectList, teamSelectList, teamTaxsSelectList, memberSave } from '@/api/members';
const BASE_URL = process.env.BASE_API;
export default {
  props: {
    visiable: Boolean,
    projectNo: {
      type: String,
      default: ''
    },
    formData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      formLabelWidth: '120px',
      teamList: [],
      // 班组列表
      teamTaxsList: [],
      // 劳务公司列表
      projectList: [],
      // 项目列表
      memberUploadURL: BASE_URL + '/empTeam/member/upload',
      templateUrl: 'https://gyoss.jianjianggong.cn/common/template/签约导入模板.xlsx',
      form: {
        teamNo: '',
        teamName: '',
        monitorName: '',
        taxName: '',
        taxNo: '',
        projectNo: '',
        projectName: '',
        merchantNo: '',
        fileList: []
      },
      rules: {
        teamNo: [{
          required: true,
          message: '请选择班组',
          trigger: 'blur'
        }],
        taxNo: [{
          required: true,
          message: '请选择签约劳务公司',
          trigger: 'blur'
        }],
        projectNo: [{
          required: true,
          message: '请选择签约项目',
          trigger: 'blur'
        }],
        checkFile: [{
          required: true,
          validator: this.fileMustUpload,
          trigger: "change"
        }]
      }
    };
  },
  computed: {
    dialogFormVisible: {
      get() {
        return this.visiable;
      },
      set(value) {
        this.$emit('update:visiable', value);
      }
    },
    title() {
      return '班组成员签约';
    },
    exData() {
      return {
        teamNo: this.form.teamNo,
        taxNo: this.form.taxNo,
        merchantNo: this.form.merchantNo,
        projectNo: this.form.projectNo
      };
    }
  },
  methods: {
    opened() {
      this.getTeamList();
    },
    fileMustUpload(rule, value, callback) {
      if (this.form.fileList.length === 0) {
        callback("请上传附件");
      } else {
        callback();
      }
    },
    async getTeamList() {
      const res = await teamSelectList();
      this.teamList = res.data;
    },
    teamTaxsSelectListAPI() {
      // 筛选数据，赋值
      const teamItem = this.teamList.find(item => {
        return item.teamNo === this.form.teamNo;
      });
      this.form.teamName = teamItem.teamName || '';
      this.form.monitorName = teamItem.monitorName || '';

      // 数据清空
      this.form.taxName = "";
      this.form.taxNo = "";
      this.form.projectNo = "";
      this.form.projectName = "";
      this.form.merchantNo = "";
      this.teamTaxsList = [];
      this.projectList = [];
      if (this.form.teamNo) {
        const params = {
          teamNo: this.form.teamNo
        };
        teamTaxsSelectList(params).then(res => {
          this.teamTaxsList = res.data;
        });
      }
    },
    teamProjectSelectListAPI() {
      // 数据清空
      this.form.projectNo = "";
      this.form.projectName = "";
      this.form.merchantNo = "";
      this.projectList = [];
      if (this.form.taxNo) {
        const params = {
          taxNo: this.form.taxNo,
          teamNo: this.form.teamNo
        };
        teamProjectSelectList(params).then(res => {
          this.projectList = res.data;
        });
      }
    },
    teamProjectChange() {
      const projectItem = this.projectList.find(item => {
        return item.projectNo === this.form.projectNo;
      });
      this.form.projectName = projectItem.projectName || '';
      this.form.merchantNo = projectItem.merchantNo || '';
    },
    beforeUpload(file) {
      const isExcel = isExcelFile(file);
      const isLt3M = file.size / 1024 / 1024 < 2;
      if (!isExcel) {
        this.$message.error('上传文件只能是Excel格式!');
      }
      if (!isLt3M) {
        this.$message.error('上传文件大小不能超过 2MB!');
      }
      return isExcel && isLt3M;
    },
    handleExceed(files, fileList) {
      this.$message.warning('当前限制选择 1 个文件，本次选择了1个文件。');
    },
    handleRemove() {
      this.form.fileList = [];
      this.$refs["ruleForm"].validateField(["checkFile"]);
    },
    onSuccess(res, file) {
      if (res.code === 'BASE.000') {
        this.$refs.upload.clearFiles();
        this.memberSaveAPI(res.data);
      } else {
        this.$message.error(res.errMsg);
      }
      this.$refs.upload.clearFiles();
    },
    onError() {
      this.$message.error('上传失败,请稍后重试！');
    },
    memberSaveAPI(cacheKey) {
      memberSave({
        cacheKey
      }).then(() => {
        this.$message.success('提交成功');
        this.$refs['ruleForm'].resetFields();
        this.$emit('onSuccess');
        this.dialogFormVisible = false;
      });
    },
    doSuccessConfirm(index, row) {
      this.$confirm('平台将会短信通知班组成员前往小程序签约，是否确定提交？', '温馨提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        this.$refs.upload.submit();
      }).catch(() => {});
    },
    changeFiles(file, fileList) {
      this.file = file;
      this.form.fileList = fileList;
      this.$refs["ruleForm"].validateField(["checkFile"]);
    },
    onClose() {
      this.handleClose();
      this.dialogFormVisible = false;
    },
    handleClose(done) {
      this.$refs['ruleForm'].resetFields();
      done && done();
    },
    onSure() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.doSuccessConfirm();
        } else {
          return false;
        }
      });
    }
  }
};